<template>
    <span v-if="item">
        <span class="me-2">
            <input class="checkbox-ctl" type="checkbox" aria-checked="true" v-model="item.checked" @click="checkToggle(item.id)">
        </span>
        <span> {{ item.name }} </span>

        <ul v-if="item && item.actions && item.actions.length > 0" class="d-flex justify-content-start">
            <li v-for="(action, a) in item.actions" :key="a" class="me-5">
                <span class="me-2">
                    <input class="checkbox-ctl" type="checkbox" aria-checked="true" :checked="action.checked" v-model="action.checked">
                </span>
                <span> {{ action.name }} </span>
            </li>
        </ul>
    </span>
</template>

<script>
    export default {
        name: "menu-item",
        props: ['item', 'index'],
        components: {
            'menu-item': this
        },
        data: function (){
            return {
                divider: true,
                inset: true
            }
        },
        methods: {
            checkToggle(index, key =''){
                this.$emit('check-toggle', '', index)
            },
        }
    }
</script>

<style scoped>

</style>